<div *ngIf="!artist && !loadFail" class="elfa-container white-bg">
  <div class="d-flex flex-column justify-content-around align-items-center product holding">
    <div class="flex-100">
      <span>Loading Details</span>
    </div>
    <div class="flex-100">
      <img [src]="settings.holdingImage?.medium" alt="Product Waiting" />
    </div>
  </div>
</div>
<div *ngIf="!artist && loadFail" class="elfa-container white-bg">
  <div class="d-flex flex-column justify-content-around align-items-center product holding">
    <div class="flex-100">
      <span>Sorry, there was a problem with this artist</span>
    </div>
  </div>
</div>
<div *ngIf="artist" class="d-flex flex-row flex-wrap justify-content-start align-items-stretch elfa-container white-bg">
  <div class="flex-100">
    <div class="d-flex flex-row flex-wrap justify-content-start align-items-stretch">
      <div class="flex-100 d-md-none">
        <h1 class="mobile-title">{{artist?.name}}</h1>
      </div>
      <div class="flex-100 flex-md-50 artist-image">
        <div>
          <img [defaultImage]="settings?.holdingImage?.medium" [lazyLoad]="artist?.pictures[0]?.full" [alt]="artist.name" />
        </div>
      </div>
      <div class="flex-100 flex-md-50 mt-2">
        <h1 class="d-none d-md-block artist-title">{{artist.name}}</h1>
        <div class="d-flex flex-row flex-wrap mt-2">
          <div *ngIf="artist.manufacturers?.length > 0" class="flex-100 d-flex flex-row flex-wrap justify-content-around align-items-center">
            <div *ngFor="let manufacturer of artist.manufacturers" class="flex-50">
              <a [routerLink]="['/manufacturers', manufacturer.seName]" routerLinkActive="router-link-active">
                <img [src]="manufacturer.pictureUrl" [alt]="manufacturer.name" class="artist-manufacturer">
              </a>
            </div>
          </div>
          <div class="flex-100">
            <div class="artist-card" [innerHtml]="artist.description | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-100 mt-2" *ngIf="artist?.products?.length > 0">
    <span class="artist-products">{{artist.name}} - Products</span>
    <app-products-carousel [products]="artist.products"></app-products-carousel>
  </div>
</div>
