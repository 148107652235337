import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { BreadcrumbModule } from 'src/@omnial/navigation/breadcrumbs/breadcrumb.module';
import { PipesModule } from 'src/@omnial/_pipes/pipes.module';
import { ProductReviewModule } from 'src/@omnial/shared/product-review/product-review.module';
import { ProductsCarouselModule } from 'src/@omnial/shared/products-carousel/products-carousel.module';
import { RatingModule } from 'src/@omnial/shared/rating/rating.module';
import { ProductFilterModule } from 'src/@omnial/product-controls/product-filters/product-filters.module';
import { ArtistsComponent } from './artists/artists.component';
import { ArtistComponent } from './artists/artist/artist.component';
import { ClientSharedModule } from 'src/app/shared/_client/client.shared.module';
import { ClientPageRoutingModule } from './client.page-routing.module';


@NgModule({
  imports: [
    ClientPageRoutingModule,
    CommonModule,
    RouterModule,
    RatingModule,
    ProductsCarouselModule,
    ReactiveFormsModule,
    MaterialBaseModule,
    MaterialExtendModule,
    LazyLoadImageModule,
    ProductReviewModule,
    PipesModule,
    LazyLoadImageModule,
    BreadcrumbModule,
    ClientSharedModule,
    ProductFilterModule
  ],
  declarations: [ArtistsComponent, ArtistComponent],
  exports: [ArtistsComponent, ArtistComponent]
})
export class ClientPageModule { }
