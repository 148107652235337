<div div class="flex-100">
  <div class="d-flex flex-row flex-wrap justify-content-start align-items-start">
    <div class="flex-100 d-flex flex-row flex-wrap products-wrapper">
      <div *ngFor="let artist of artists" class="flex-100 flex-md-33 product-grid">
        <mat-card class="product-item text-center">
          <div class="aspect-ratio-box">
            <div class="aspect-ratio-box-inside">
              <a [routerLink]="['/artists', artist.seName]" class="image-link">
                <img [defaultImage]="settings?.holdingImage?.medium" [lazyLoad]="artist.pictures[0]?.medium" [alt]="artist.name">
              </a>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap justify-content-start">
            <div class="flex-100">
              <a [routerLink]="['/artists', artist.seName]" class="tip-title">
                {{artist.name}}
              </a>
            </div>
          </div>
          <div *ngIf="artist.manufacturers?.length > 0" class="d-flex flex-row flex-wrap justify-content-around align-items-center">
            <div *ngFor="let manufacturer of artist.manufacturers" class="flex-50">
              <img [src]="manufacturer.pictureUrl" [alt]="manufacturer.name" class="artist-manufacturer">
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
