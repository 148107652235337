import { Component, Input, OnInit } from '@angular/core';
import { Artist } from 'src/app/_models/artist.model';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss']
})
export class ArtistComponent implements OnInit {
  @Input() artist: Artist;
  public loadFail: boolean;

  constructor(public settings: AppSettings) { }

  ngOnInit(): void {
  }
}
